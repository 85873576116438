import { FormattedMessage } from "@/i18n/i18n-client";
import {
  CartCustomItem,
  CartSimpleItem,
  CartFragment,
  CartStepEnum,
  VariantInfoFragment,
} from "@/generated/graphql";
import { CartContextType } from "./CartContext";
import { AnalyticsActions } from "../analytics-accounts/utils";
import { createItemEvent } from "@/lib/analytics-accounts/eventsUtilities";
import { type SimpleProductProps } from "@/templates/types";

export function fireAddToCartEvent(
  newItem: CartFragment["items"][0] | undefined | null,
  items: CartFragment["items"][0][],
  customerId: string | undefined
) {
  if (!newItem || !items.length) return;
  AnalyticsActions.fireEvent({
    name: "Add_to_Cart",
    parameters: {
      event_category: "Shop",
      event_label: newItem.product?.title ?? "",
      content_category: undefined,
      ...createItemEvent(newItem, customerId),
      items: items?.map((item) => createItemEvent(item, customerId)),
    },
  });
}

export function formatAvailableQuantity(
  selectedVariant: VariantInfoFragment | undefined
): SimpleProductProps["availableQuantity"] | undefined {
  const stockQuantity = selectedVariant?.trackQuantity
    ? selectedVariant?.quantity!
    : Number.POSITIVE_INFINITY;
  const maxPerCart = selectedVariant?.maxPerCart ?? Number.POSITIVE_INFINITY;
  const minPerCart = selectedVariant?.minPerCart ?? 1;

  if (!selectedVariant?.cartLimitsEnabled && !selectedVariant?.trackQuantity) {
    return {
      type: "infinity",
      max: Number.POSITIVE_INFINITY,
    };
  }
  if (!selectedVariant?.cartLimitsEnabled || stockQuantity < minPerCart) {
    return {
      type: "stock",
      max: stockQuantity,
      min: minPerCart,
    };
  }
  if (!selectedVariant?.trackQuantity || stockQuantity >= minPerCart) {
    return {
      type: "cart",
      max: stockQuantity > maxPerCart ? maxPerCart : stockQuantity,
      min: minPerCart,
    };
  }
  return;
}

export const getCartItem = (
  cart: CartContextType,
  productId: string,
  itemId?: string
) => {
  if (productId?.startsWith("ProductVariant_")) {
    return cart?.items?.find(
      (item) => (item as CartSimpleItem)?.variant?.id === productId
    );
  }
  return cart?.items?.find(
    (item) =>
      (item as CartCustomItem).product?.id === productId ||
      (item as CartCustomItem).id === itemId
  );
};

export const getSelectedVariants = (item: CartCustomItem) => {
  const categoryId = item?.categories[0].category.id;
  return item?.categories[0].selectedVariants.map((variant) => ({
    categoryId,
    variantId: variant.variant?.id || "",
    quantity: variant.quantity,
  }));
};

const StepsMessagesMap = {
  [CartStepEnum.Information]: (
    <FormattedMessage defaultMessage="Information" id="E80WrK" />
  ),
  [CartStepEnum.Delivery]: (
    <FormattedMessage defaultMessage="Delivery" id="drqP2L" />
  ),
  [CartStepEnum.Payment]: (
    <FormattedMessage defaultMessage="Payment" id="NmK6zy" />
  ),
  CART: <FormattedMessage defaultMessage="Cart" id="2tqQFl" />,
  INVALID_ORDER: (
    <FormattedMessage defaultMessage="Invalid Order" id="PN3WW/" />
  ),
};

// this is an array of sorted checkout steps
export const StepsMessagesArray = [
  StepsMessagesMap["CART"],
  StepsMessagesMap[CartStepEnum.Information],
  StepsMessagesMap[CartStepEnum.Delivery],
  StepsMessagesMap[CartStepEnum.Payment],
];

export function getActiveStepInfo(pathname: string) {
  const isInvalidOrder = pathname?.includes("invalid-order");
  const discriminator = isInvalidOrder ? 4 : 3;

  if (pathname?.includes("confirmation")) {
    return {
      name: "Confirmation",
      number: discriminator + 2,
      title: <FormattedMessage defaultMessage="Confirmation" id="Pswssl" />,
    };
  }

  if (pathname?.includes("payment")) {
    return {
      name: "Payment",
      number: discriminator + 1,
      title: <FormattedMessage defaultMessage="Payment" id="NmK6zy" />,
    };
  }

  if (pathname?.includes("invalid-order")) {
    return {
      name: "Invalid Order",
      number: discriminator,
      title: <FormattedMessage defaultMessage="Invalid Order" id="PN3WW/" />,
    };
  }

  if (pathname?.includes("delivery")) {
    return {
      name: "Delivery",
      number: 3,
      title: <FormattedMessage defaultMessage="Delivery" id="drqP2L" />,
    };
  }

  if (pathname?.includes("cart")) {
    return {
      name: "Cart",
      number: 1,
      title: <FormattedMessage defaultMessage="Cart" id="2tqQFl" />,
    };
  }

  // should be last one as all paths includes checkout
  if (pathname?.includes("checkout")) {
    return {
      name: "Information",
      number: 2,
      title: <FormattedMessage defaultMessage="Information" id="E80WrK" />,
    };
  }

  return {
    name: "Other",
    number: -1,
  };
}
